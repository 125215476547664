<template>
    <div>

    <el-main style=" padding-top: 5px;padding-bottom: initial  ">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-row>
                        <el-col :lg="24">
                            <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                                <el-row>
                                    <el-col :lg="24" style="line-height: 27px;">
                                        <strong style=" font-size: 16px; ">外购单据一览</strong>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-col>
                        <el-col :lg="24">
                            <el-row>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-form-item label="起始日期:">
                                        <el-date-picker
                                                v-model="startDate"
                                                align="right"
                                                type="date"
                                                size="mini"
                                                placeholder="选择日期"
                                                :picker-options="pickerOptions">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="4" :sm="12" :md="8">
                                    <el-form-item label="结束日期:" >
                                        <el-date-picker
                                                v-model="endDate"
                                                align="right"
                                                type="date"
                                                size="mini"
                                                placeholder="选择日期"
                                                :picker-options="pickerOptions">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>

                                <el-col :lg="2" >
                                    <el-button style=" margin-top: 8px; " id="createBill" type="primary" round  size="mini">搜索查询</el-button>
                                </el-col>
                                <el-col :lg="6" :sm="12" :md="8">
                                    <el-row>
                                        <el-col class="pull-left" :lg="4">
                                            <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export ref="xToolbar2">
                                            </vxe-toolbar>
                                        </el-col>

                                    </el-row>
                                </el-col>
                            </el-row>
                        </el-col>

                    </el-row>
<el-row>
    <el-col :lg="24">
        <vxe-grid
                  border
                  resizable
                  show-overflow
                  keep-source
                  ref="xGrid"
                  size="mini"
                  height="670"
                  highlight-hover-row
                  :print-config="{}"
                  :data="tableDataMain"
                  :columns="tableColumn"
                  :mouse-config="{selected: false}"
                  :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                  :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
            <template #pager>
                <vxe-pager
                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                        :current-page.sync="tablePage.currentPage"
                        :page-size.sync="tablePage.pageSize"
                        :total="tablePage.total"
                        @page-change="handlePageChange">
                </vxe-pager>
            </template>
                <template #category_edit="{row}"><!-- 类别 -->
                    <el-select v-model="row.category" size="mini" no-data-text="没有搜索结果!" placeholder="可搜索查询" clearable  >
                        <el-option v-for="item in category" :key="item.id" :label="item.category" :value="item.category">
                            <span style="margin:15px;">{{item.category}}</span>
                        </el-option>
                    </el-select>
                </template>
                <template #specification_edit="{row}"><!-- 实际规格 -->
                    <el-select v-model="row.specification" size="mini" no-data-text="没有搜索结果!" placeholder="可搜索查询" clearable filterable >
                        <el-option v-for="item in specification" :key="item.id" :label="item.specification" :value="item.specification">
                            <span style="margin:15px;">{{item.specification}}</span>
                        </el-option>
                    </el-select>
                </template>
                <template #unit_edit="{row}"><!-- 单位 -->
                    <el-select v-model="row.unit" size="mini" no-data-text="没有搜索结果!" placeholder="可搜索查询" clearable  >
                        <el-option v-for="item in unit" :key="item.id" :label="item.unit" :value="item.unit">
                            <span style="margin:15px;">{{item.unit}}</span>
                        </el-option>
                    </el-select>
                </template>
        </vxe-grid>
    </el-col>


</el-row>

                </el-form>
            </el-main>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'

    import {filterRender} from "@/utils/gird-filter";
    export default {

        data() {
            return {
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                       }
                   }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                       }
                   }]
               },
                tableColumn: [
                    {field:'unit', width: 100 ,title: '审核' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                       }
                   },
                    {field:'unit', width: 100 ,title: '类型' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                       }
                   },
                    {field:'unit', width: 100 ,title: '单号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                       }
                   },{field:'unit', width: 100 ,title: '日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                       }
                   },{field:'unit', width: 100 ,title: '供应商' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                       }
                   },{field:'unit', width: 100 ,title: '支数' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                       }
                   },
                    {field:'unit', width: 100 ,title: '过磅重(吨)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                       }
                   },{field:'unit', width: 100 ,title: '金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                       }
                   },{field:'unit', width: 100 ,title: '合计金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                       }
                   },{field:'unit', width: 100 ,title: '已付款' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                       }
                   },{field:'unit', width: 100 ,title: '未付款' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                       }
                   },{field:'unit', width: 100 ,title: '仓库' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'input', {size: 'mini'}, this.filterData)
                       }
                   }
                ],
              tableDataMain : [],
              filterData: {},
                unit:[
                    {id:1,value:1,unit:'吨',label:'吨'},
                    {id:2,value:2,unit:'支',label:'支'}
                ],
                category:[
                    {id:1,category:'方管',value:1,label:'方管'},
                    {id:2,category:'矩管',value:2,label:'矩管'}
                ],
                startDate:'',
                endDate:'',
                specification:[
                    {id:1,specification:'60*40*4*6米',value:1,label:'60*40*4*6米'},
                    {id:2,specification:'200*100*4*6米',value:2,label:'200*100*4*6米'},
                    {id:3,specification:'200*100米4*12米',value:3,label:'200*100米4*12米'},
                    {id:4,specification:'150*100*4*6米',value:4,label:'150*100*4*6米'},
                    {id:5,specification:'50*25*3*6米',value:5,label:'50*25*3*6米'},
                    {id:6,specification:'50*50*3.5*6米',value:6,label:'50*50*3.5*6米'},
                    {id:7,specification:'160*60*6*6米',value:7,label:'160*60*6*6米'},
                    {id:8,specification:'200*100*6*12米',value:8,label:'200*100*6*12米'},
                    {id:9,specification:'250*250*12*12米',value:9,label:'250*250*12*12米'},
                    {id:10,specification:'60*60*6*米',value:10,label:'60*60*6*米'},
                    ],
                form: {
                    name: '',
                    region: '',
                    date1: '',
                    date2: '',
                    delivery: false,
                    type: [],
                    resource: '',
                    desc: ''
               },
                activeName:'first',
                checked: false,
                regionList: [],
                restaurants: [
                    {value: '前端', name: '前端'},
                    {value: '后端', name: '后端'}
                ],
                sexList: [
                    {label: '', value: ''},
                    {label: '男', value: '1'},
                    {label: '女', value: '0'}
                ],

           }
       },
        methods: {
            handlePageChange ({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                //this.findList()
           },
            roleFetchSuggestions (queryString, cb) {
                var restaurants = this.restaurants
                var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants
                clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    cb(results)
               }, 1000 )
           },
            editMethod ({row, column}) {
                const $table = this.$refs.xGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            formatDate (value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName ({rowIndex}) {
                if (rowIndex%2 === 1) {
                    return 'success-row';
               } else{
                    return '';
               }
           },
            onSubmit() {
                //console.log('submit!');
           }
       },
        created () {
            var list = [];
            for (var index = 0; index < 30; index++) {
                list.push({
                    id: index,
                    category: "",
                    specification: "",
                    unit: "",
                    count: "",
                    quotation: "",
                    actual_thickness : "",
                    polish_roll : "",//打磨驳口
                    single_weight : "",
                    recently_price : "",
                    price: "",
                    registrar: "",
                    customer: "",
                    record_date : "",
                    quotationNumber: ""
               });
           }
            this.loading = true
            this.tableDataMain = list;
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {display:flex; flex-direction:row;justcontent:space-between}
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }
    .el-form-item__content .el-input{
        width: 130px;
   }
</style>
